import NavbarItem from "./buttons/NavbarItem";
import {styled} from "@chakra-ui/react";
import {useEffect, useState} from "react";

const CustomNavBar = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "30%",
    alignItems: "center",
  }
})

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function Navbar() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    windowSize.innerWidth > 650 ? (
      <CustomNavBar>
        <NavbarItem name="HOME" url="/"/>
        <NavbarItem name="PROJECTS" url="projects"/>
      </CustomNavBar>
    ) : (
      <CustomNavBar>
        Mobile not supported for now
      </CustomNavBar>
    )
  );
}

export default Navbar;