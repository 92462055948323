import React from 'react';
import {ChakraProvider, styled} from "@chakra-ui/react";
import HomePage from "./pages/HomePage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Projects from "./pages/Projects";

const Background = styled("div", {
  baseStyle: {
    backgroundImage: require("./assets/background.png"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  }
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <div>404</div>
  },
  {
    path: "/projects",
    element: <Projects />,
    errorElement: <div>404</div>
  }
]);

function App() {
  return (
      <ChakraProvider>
        <Background>
          <RouterProvider router={router}/>
        </Background>
      </ChakraProvider>
  );
}

export default App;
