import TemplatePage from "./TemplatePage";


function Projects() {
  return (
    <TemplatePage>
      My Projects
    </TemplatePage>
  );
}

export default Projects;