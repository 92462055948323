import {Text} from "@chakra-ui/react";

function Footer() {

  return (
    <div>
      <Text>
        footer
      </Text>
    </div>
  )
}

export default Footer;