import {ReactNode} from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface TemplatePageProps {
  children: ReactNode;
}

function TemplatePage({
  children
}: TemplatePageProps) {

  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default TemplatePage;