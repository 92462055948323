import TemplatePage from "./TemplatePage";


function HomePage() {
  return (
    <TemplatePage>
      test
    </TemplatePage>
  );
}

export default HomePage;