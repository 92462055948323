import {styled, Text} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";

const Button = styled("button", {
  baseStyle: {
    display: "flex",
    marginLeft: 7,
    marginRight: 7,
    marginTop: 5,
  }
});

const CustomLink = styled(Link, {
  baseStyle: {
    display: "flex",
    textDecoration: "none",
  }
})

const Arrow = styled("div", {
  baseStyle: {
    marginTop: -2,
    marginLeft: 1,
  }
});

interface NavbarItemProps {
  name: string;
  url: string;
}

function NavbarItem({
  name,
  url,
}: NavbarItemProps) {

  return (
    <Button>
      <CustomLink to={url}>
        <Text fontSize="xl" fontFamily="Alegreya Sans, sans-serif">{name}</Text>
        <Arrow>
          <ArrowForwardIcon style={{rotate: "-45deg"}}/>
        </Arrow>
      </CustomLink>
    </Button>
  );
}

export default NavbarItem;